export const MANAGE_VIDEOS = 'App_Manager_Wix_Video_Main_CTA';
export const ADD_VIDEO_ELEMENT = 'App_Manager_Wix_Video_Quick_Action_1';
export const MANAGE_CHANNELS = 'App_Manager_Wix_Video_Quick_Action_3';
export const LIVE_STREAM = 'App_Manager_Wix_Video_Quick_Action_4';

export const OPEN_VIDEO_MANAGER_IN_BM = 'openVideoManagerInBM';
export const OPEN_VIDEO_SECTION_IN_ADD_PANEL = 'openVideoSectionInAddPanel';
export const OPEN_VIDEO_IN_APP_MARKET = 'openVideoInAppMarket';
export const OPEN_CHANNELS_TAB = 'openChannelsTab';
export const OPEN_LIVE_STREAM_TAB = 'openLiveStreamTab';

export const VIDEOS_SECTION = '/video-library/videos';
export const LIVE_STREAM_SECTION = '/video-library/live-videos';
export const CHANNELS_SECTION = '/video-library/channels';

export const UPGRADE_TEXT = 'App_Manager_Wix_Video_Upgrade_Text';
export const UPGRADE_LINK_TEXT = 'App_Manager_Wix_Video_Upgrade_Link';

export const PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
